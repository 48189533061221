import React from 'react';
import DateBox from 'devextreme-react/date-box';
import LDH from "../helpers/LeopardDataHelper";
import LRH from "../helpers/LeopardReactHelper";

export class LeopardDocDateTimeControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldValue: LDH.IsValueEmpty(props.rootProps.data) ? null : props.rootProps.data.toString(),
            displayName: props.rootProps.schema.field.displayName,
            disabled: !LDH.IsObjectNull(props.rootProps.schema.field.disabled) &&
                !LDH.IsValueEmpty(props.rootProps.schema.field.disabled) &&
                props.rootProps.schema.field.disabled === true,
            readOnly: !LDH.IsObjectNull(props.rootProps.schema.field.readOnly) &&
                !LDH.IsValueEmpty(props.rootProps.schema.field.readOnly) &&
                props.rootProps.schema.field.readOnly === true
        };
        this.controlId = "LeopardDocEditor_" + LDH.GenerateGuid();
        this.uiObjectInstances = [];
    }

    fieldValueOnChange = (data) => {
        this.setState({fieldValue: data.e.value}, function () {
            data.props.onValueChanged({value: data.e.value});
        });
    }

    setUIInstance = (data) => {
        if (data.e === undefined || data.e === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.name] = data.e;
    };

    componentWillUnmount = () => {
        let uiObjectInstances = this.uiObjectInstances;
        LRH.DisposeUIInstancesFromList(uiObjectInstances);
    };

    render() {
        return (
            <React.Fragment>
                <label className={"leopard-doc-editor-label"} htmlFor={this.controlId}>
                    {this.state.displayName}
                </label>
                <DateBox id={this.controlId} name={this.controlId} defaultValue={this.state.fieldValue}
                         className={"leopard-doc-editor-datetime-control"} type="datetime"
                         disabled={this.state.disabled} readOnly={this.state.readOnly}
                         ref={(e) => this.setUIInstance({e: e, name: this.controlId})}
                         onValueChanged={(e) => this.fieldValueOnChange({e: e, props: this.props})}>
                </DateBox>
            </React.Fragment>
        )
    }
}

LeopardDocDateTimeControl.getDerivedStateFromProps = (nextProps, prevState) => {
    return null;
};
import React from 'react';
import LDH from '../helpers/LeopardDataHelper';
import websocket from 'websocket';
import LeopardCognitoConfig from "../foundation/LeopardCognitoConfig";
import aws4 from "aws4";

class LeopardWebsocketHelper extends React.Component {
    static OpenConnection = () => {
        let endpoint = LDH.WebsocketEndpointAdapter();
        let endpointWithoutPrefix = endpoint.replace("wss://", "");
        let W3CWebSocket = websocket.w3cwebsocket;

        let accessInfo = {
            accessKeyId: window.userCredential.accessKeyId,
            secretAccessKey: window.userCredential.secretAccessKey,
            sessionToken: window.userCredential.sessionToken
        };

        console.log(endpointWithoutPrefix);
        let signedReq = aws4.sign({
            host: endpointWithoutPrefix,
            path: `/?X-Amz-Security-Token=${encodeURIComponent(accessInfo.sessionToken)}`,
            region: LeopardCognitoConfig.AmplifyAuthAndAPIConfig.Auth.region,
            service: "execute-api",
            signQuery: true
        }, accessInfo);

        let client = new W3CWebSocket(`${endpoint}${signedReq.path}`);
        client.onerror = function (err) {
            console.log('Websocket client error', err);
            client.close();
        };

        client.onopen = function (e) {
            console.log('Websocket client connected', e);
            if (client.readyState === 1) {
                client.send({});
            }
        };

        client.onclose = function () {
            console.log('Websocket client disconnected');

            setTimeout(function () {
                console.log('Websocket client reconnecting');
                LeopardWebsocketHelper.OpenConnection();
            }, 1000);
        };

        client.onmessage = function (e) {
            console.log('Websocket client message', e);

            if (!LDH.IsObjectNull(e) && !LDH.IsObjectNull(e.data) &&
                !LDH.IsValueEmpty(e.data) &&
                e.data.indexOf("Internal server error") > -1 &&
                e.data.indexOf("connectionId") > -1) {
                let value = JSON.parse(e.data);
                window.websocketConnectionId = value.connectionId;
            }

            if (!LDH.IsObjectNull(e) && !LDH.IsObjectNull(e.data) &&
                !LDH.IsValueEmpty(e.data)) {
                let jsonData = JSON.parse(e.data);

                if (!LDH.IsObjectNull(jsonData) && !LDH.IsObjectNull(jsonData.eventkey) &&
                    !LDH.IsValueEmpty(jsonData.eventkey)) {
                    LeopardWebsocketHelper.UpdatePendingEvent(
                        jsonData.eventkey, "completed", jsonData);
                }
            }
        };
        window.websocketInstance = client;
    };

    static SendMessage = (message) => {
        if (LDH.IsObjectNull(window.websocketInstance)) {
            return;
        }
        let client = window.websocketInstance;
        if (client.readyState === 1) {
            client.send(message);
        }
    };

    static CloseConnection = () => {
        if (LDH.IsObjectNull(window.websocketInstance)) {
            return;
        }
        window.websocketInstance.close();
    };

    static GetMessageTemplate = (type, connectionId, data, eventKey) => {
        if (LDH.IsObjectNull(window.browserTabId) || LDH.IsValueEmpty(window.browserTabId)) {
            window.browserTabId = LDH.GenerateGuid();
        }
        return {
            "specversion": "1.0",
            "type": type,
            "source": "urn:" + window.browserTabId + ":tbd:loadprofile",
            "id": eventKey,
            "time": new Date().toISOString(),
            "responseroute": "{\"type\":\"direct\",\"connectionid\":\"" + connectionId + "\"}",
            "data": data
        }
    }

    static AddPendingEvent = (eventKey, uiObjectId, dataViewId, eventType) => {
        if (LDH.IsObjectNull(window.pendingWebsocketEventKeys)) {
            window.pendingWebsocketEventKeys = [];
        }
        for (let i = 0; i < window.pendingWebsocketEventKeys.length; i++) {
            let event = window.pendingWebsocketEventKeys[i];
            if (LDH.IsObjectNull(event) || LDH.IsObjectNull(event.eventKey)) {
                continue;
            }
            if (event.eventKey === eventKey) {
                delete window.pendingWebsocketEventKeys[i];
                break;
            }
        }
        window.pendingWebsocketEventKeys.push({
            eventKey: eventKey,
            status: "sent",
            dataViewId: dataViewId,
            eventType: eventType,
            uiObjectId: uiObjectId,
            data: null
        });
    }

    static UpdatePendingEvent = (eventKey, status, response) => {
        let event = LeopardWebsocketHelper.FindPendingEventByKey(eventKey);
        if (LDH.IsObjectNull(event)) return;
        event.status = status;
        event.data = response;
    }

    static RemovePendingEventByDataViewId = (dataViewId) => {
        if (LDH.IsObjectNull(window.pendingWebsocketEventKeys)) {
            window.pendingWebsocketEventKeys = [];
        }
        for (let i = 0; i < window.pendingWebsocketEventKeys.length; i++) {
            let event = window.pendingWebsocketEventKeys[i];
            if (LDH.IsObjectNull(event) || LDH.IsObjectNull(event.dataViewId)) {
                continue;
            }
            if (event.dataViewId === dataViewId) {
                delete window.pendingWebsocketEventKeys[i];
                i--;
            }
        }
    }

    static FindPendingEventByKey = (eventKey) => {
        for (let i = 0; i < window.pendingWebsocketEventKeys.length; i++) {
            let event = window.pendingWebsocketEventKeys[i];
            if (LDH.IsObjectNull(event) || LDH.IsObjectNull(event.eventKey)) {
                continue;
            }
            if (event.eventKey === eventKey) return event;
        }
        return null;
    }

    static WaitForWebsocketResponse = (eventKey, callback) => {
        let loopCount = 0;
        let pendingCallback = setInterval(function () {
            loopCount++;

            if (loopCount > 600) {
                callback("aborted");
                clearInterval(pendingCallback);
                return;
            }

            let eventObj = LeopardWebsocketHelper.FindPendingEventByKey(eventKey);
            if (LDH.IsObjectNull(eventObj)) {
                callback("aborted");
                clearInterval(pendingCallback);
                return;
            }

            if (eventObj.status !== "completed") {
                return;
            }

            if (!LDH.IsObjectNull(eventObj) && !LDH.IsObjectNull(eventObj.data) &&
                !LDH.IsObjectNull(eventObj.data.data) && eventObj.data.data.length > 0) {
                callback("completed", eventObj.data.data[0]);
            } else {
                callback("completed", null);
            }
            clearInterval(pendingCallback);
        }, 50);
        return pendingCallback;
    }
}

export default LeopardWebsocketHelper;


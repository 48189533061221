import React, {Component} from 'react';
import {Button, Popup, TextArea} from "devextreme-react";
import LDH from '../helpers/LeopardDataHelper';

class LeopardTextEditorPopup extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.state = {
            textEditorValue: null,
            popupVisible: false,
            popupTitle: null
        };
        this.exampleData = "";
    }

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    popupOnHide = () => {
        this.exampleData = "";
        window.Global_PopupTempData = null;
    };

    popupOnShowing = () => {
        let title = this.props.popupTitle;
        let value = window.Global_PopupTempData;
        this.exampleData = this.props.exampleData;
        this.setState({textEditorValue: value, popupTitle: title});
    };

    exampleButtonOnClick = () => {
        this.setState({textEditorValue: this.exampleData});
    };

    cancelButtonOnClick = () => {
        let instance = window.Global_PopupTempObjectInstance;
        if (!LDH.IsObjectNull(instance.popup)) {
            instance.popup.hide();
        }
    };

    applyButtonOnClick = () => {
        let instance = window.Global_PopupTempObjectInstance;
        if (!LDH.IsObjectNull(instance.popup) && !LDH.IsObjectNull(instance.textbox)) {
            instance.textbox.option("value", this.state.textEditorValue);
            instance.popup.hide();
        }
    };

    onValueChanged = (e) => {
        this.setState({textEditorValue: e.event.target.value});
    };

    // =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    initializeTextEditorAccessPanel() {
        return (
            <div style={{textAlign: "right", padding: "0 10px 0 10px"}}>
                <span className={"leopard-loading-icon"} style={{marginLeft: "520px"}}>
                    <i className="fas fa-spinner fa-pulse" style={{
                        color: "#FF8100", fontSize: "25px", display: "none"
                    }}></i>
                </span>
                <span style={{paddingRight: "15px"}}>
                    <Button className="leopard-button" text={'Example'}
                            onClick={(e) => this.exampleButtonOnClick(e)}>
                    </Button>
                </span>
                <span style={{paddingRight: "15px"}}>
                    <Button className="leopard-button" text={'Cancel'}
                            onClick={(e) => this.cancelButtonOnClick(e)}>
                    </Button>
                </span>
                <span style={{paddingRight: "10px"}}>
                    <Button className="leopard-button" text={'Apply'}
                            onClick={(e) => this.applyButtonOnClick(e)}>
                    </Button>
                </span>
            </div>
        );
    };

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        return (
            <Popup className={'leopard-popup-window edit-form'} visible={this.state.popupVisible}
                   dragEnabled={true} closeOnOutsideClick={false} onHiding={this.popupOnHide}
                   onShowing={this.popupOnShowing} showTitle={true} title={this.state.popupTitle}
                   width={this.props.popupWidth} ref={this.props.popupInstance} height={500}>
                <div style={{height: "395px", marginBottom: "20px", padding: "0 10px 0 10px"}}>
                    <TextArea height={"100%"} value={this.state.textEditorValue} onValueChanged={
                        (e) => this.onValueChanged(e)
                    }>
                    </TextArea>
                </div>
                {this.initializeTextEditorAccessPanel()}
            </Popup>
        )
    }
}

export default LeopardTextEditorPopup;

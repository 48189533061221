import React, {Component} from 'react';
import {connect} from "react-redux";
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LRH from "../helpers/LeopardReactHelper";
import LDH from "../helpers/LeopardDataHelper";
import $ from 'jquery';

import LeopardDocumentEditorEngine from "../foundation/LeopardDocumentEditorEngine";
import LeopardDocumentPreviewerEngine from "../foundation/LeopardDocumentPreviewerEngine";
import LeopardSecurity from "../security/LeopardSecurity";
import LeopardDocumentEditorDesigner from "../datashaping/LeopardDocumentEditorDesigner";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";

class LeopardDocumentEditorConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpandDesigner: false,
            isDocumentEditorReadOnly: false,
            isHideDocumentEditor: false
        }
        this.uiObjectInstances = [];
        this.documentDataJSON = null;
        this.documentDataString = "";
        this.documentDataForEditor = "";
    }

    componentDidMount() {
        setTimeout(function () {
            LRH.TriggerWindowResizeEvent();
        }, 100);
    }

    componentWillUnmount = () => {
        let uiObjectInstances = this.uiObjectInstances;
        LRH.DisposeUIInstancesFromList(uiObjectInstances);
    };

    setUIInstance = (data) => {
        if (data.e === undefined || data.e === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.name] = data.e;
    };

    documentEditorDesignerButtonOnClick = () => {
        if (LeopardSecurity.ShowErrorMessageForLeopardAdminFeature(this)) {
            return;
        }
        if (this.state.isExpandDesigner) {
            this.setState({isExpandDesigner: false}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        } else {
            this.setState({isExpandDesigner: true}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        }
    }

    documentEditorSaveButtonOnClick = () => {
        let that = this;
        let dataViewId = that.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
        let documentRef = that.props.documentDefinition;
        documentRef.documentDefinition.documentEditorDataReceivingLogic = this.documentDataString;
        that.uiObjectInstances["data-document-editor-save"].option("disabled", true);
        that.uiObjectInstances["data-previewer-edit"].option("disabled", true);
        $(".document-editor-saveprogress-" + dataViewId).show();

        LeopardAjaxHelper.UpdateDataViewDefinition(userId, organizationId, dataViewId, documentRef, function () {
            let settingsVersionOnClient = parseInt($(".dataview-settings-version").attr("settingsversion"));
            $(".dataview-settings-version").attr("settingsversion", settingsVersionOnClient + 1);
            LRH.ShowToast("Your document has been successfully updated.", "success", 5000);

            that.uiObjectInstances["data-document-editor-save"].option("disabled", false);
            that.uiObjectInstances["data-previewer-edit"].option("disabled", false);
            $(".document-editor-saveprogress-" + dataViewId).hide();
        }, function (error, sessionTimeout) {
            if (error === "version-out-of-date") {
                LRH.ShowStaticToast("Configuration outdated", "Your current configuration settings cannot be saved due " +
                    "to a newer version found on the server, please refresh your browser. ", "error", true);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to update your report. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            that.uiObjectInstances["data-document-editor-save"].option("disabled", false);
            that.uiObjectInstances["data-previewer-edit"].option("disabled", false);
            $(".document-editor-saveprogress-" + dataViewId).hide();
        });
    }

    documentDataPreviewerEditOnClick = () => {
        let that = this;
        let previewerInstance = this.uiObjectInstances["leopard_document_data_previewer"];
        if (LDH.IsObjectNull(previewerInstance)) return;

        let editorInstance = this.uiObjectInstances["leopard_document_data_editor"];
        if (LDH.IsObjectNull(editorInstance)) return;

        editorInstance.onChangeEventProcess = "disabled";
        that.updateDataPreviewerState({readOnly: false});

        this.setState({isDocumentEditorReadOnly: true}, function () {
            let $root = $(".document-editor_widget_" + that.props.dataViewId);
            $(".splitter-layout .layout-pane-primary", $root).addClass("hide-scrollbar");
            setTimeout(function () {
                editorInstance.onChangeEventProcess = "update";
            }, 100);
        });
    }

    documentDataPreviewerSaveOnClick = () => {
        let that = this;

        let previewerInstance = this.uiObjectInstances["leopard_document_data_previewer"];
        if (LDH.IsObjectNull(previewerInstance)) return;

        let editorInstance = this.uiObjectInstances["leopard_document_data_editor"];
        if (LDH.IsObjectNull(editorInstance)) return;

        this.documentDataJSON = JSON.parse(that.documentDataString);
        this.documentDataForEditor = this.documentDataJSON;

        editorInstance.onChangeEventProcess = "update";
        editorInstance.updateDocumentData(this.documentDataJSON);

        this.setState({isDocumentEditorReadOnly: false, isHideDocumentEditor: true}, function () {
            // A workaround for re-generate JSON forms after the data has been changed.
            this.setState({isHideDocumentEditor: false}, function () {
                let $root = $(".document-editor_widget_" + that.props.dataViewId);
                $(".splitter-layout .layout-pane-primary", $root).removeClass("hide-scrollbar");
            })
        });
    }

    documentDataPreviewerCancelOnClick = () => {
        let that = this;

        let previewerInstance = this.uiObjectInstances["leopard_document_data_previewer"];
        if (LDH.IsObjectNull(previewerInstance)) return;

        let editorInstance = this.uiObjectInstances["leopard_document_data_editor"];
        if (LDH.IsObjectNull(editorInstance)) return;

        editorInstance.onChangeEventProcess = "undo";
        this.setState({isDocumentEditorReadOnly: false}, function () {
            let $root = $(".document-editor_widget_" + that.props.dataViewId);
            $(".splitter-layout .layout-pane-primary", $root).removeClass("hide-scrollbar");

            setTimeout(function () {
                editorInstance.onChangeEventProcess = "update";
            }, 100);
        });
    }

    documentDataPreviewerValueOnChanged = (data) => {
        try {
            let jsonData = JSON.parse(data);
            this.documentDataJSON = jsonData;
            this.documentDataForEditor = jsonData;
            this.documentDataString = data;
        } catch (ex) {
            console.log("Prevent crash from invalid JSON while being edited.");
        }
    }

    initializeDocumentDesignerPanel() {
        let definition = null;
        if (!LDH.IsObjectNull(this.props.documentDefinition) &&
            !LDH.IsObjectNull(this.props.documentDefinition.documentDefinition)) {
            definition = this.props.documentDefinition.documentDefinition;
        }

        return (
            <div className="leopard-document-editor-designer" custom_attr_gridviewid={this.props.dataViewId}>
                <LeopardDocumentEditorDesigner
                    dataViewId={this.props.dataViewId} definition={definition}
                    documentEditorDesignerButtonOnClick={(e) => this.documentEditorDesignerButtonOnClick(e)}>
                </LeopardDocumentEditorDesigner>
            </div>
        );
    }

    initializeAdministrativeToolbar = () => {
        return (
            <div style={{height: "30px"}}>
                <span className={"leopard-no-whitespace"} style={{padding: "0 10px 0 15px"}}>
                    <LeopardTooltipWithLink
                        elementId={"DocumentEditor_Admin_ConfigureEditor_" + this.props.dataViewId}
                        labelText={"Configure Document Editor"} width={300} title={"Configure Document Editor"}
                        additionalClass={"leopard-gridview-admin-toolbar"}
                        onClick={(e) => this.documentEditorDesignerButtonOnClick({e})}
                        text={"This feature allows admin users to configure the Document Editor settings."}>
                    </LeopardTooltipWithLink>
                </span>
            </div>
        );
    }

    onSecondaryPaneSizeChange = () => {
        LRH.TriggerWindowResizeEvent();
    }

    onDataPreviewerValidated = (data) => {
        let instance = this.uiObjectInstances["data-previewer-save"];
        if (LDH.IsObjectNull(instance)) return;

        if (data.isDataValid === true) {
            instance.option("disabled", false);
        } else {
            instance.option("disabled", true);
        }
    }

    updateDataPreviewerState = (data) => {
        let that = this;
        let instance = that.uiObjectInstances["leopard_document_data_previewer"];
        if (LDH.IsObjectNull(instance)) return;

        if (!LDH.IsObjectNull(data.documentDataString)) {
            let jsonData = JSON.parse(data.documentDataString);
            this.documentDataJSON = jsonData;
            this.documentDataForEditor = jsonData;
            this.documentDataString = data.documentDataString;
        }
        instance.updateValuesInState(data);
    }

    render() {
        let that = this;
        let isDataView = this.props.isDataView;

        let definition = null;
        if (!LDH.IsObjectNull(this.props.definition) &&
            !LDH.IsObjectNull(this.props.definition.documentDefinition)) {
            definition = this.props.definition.documentDefinition;
        } else if (!LDH.IsObjectNull(this.props.documentDefinition) &&
            !LDH.IsObjectNull(this.props.documentDefinition.documentDefinition)) {
            definition = this.props.documentDefinition.documentDefinition;
        }

        let showGridViewAdminToolbar = true;
        if (this.props.viewType === "workspace") {
            showGridViewAdminToolbar = false;
        } else if (this.props.state.permissions !== undefined && this.props.state.permissions !== null) {
            showGridViewAdminToolbar = this.props.state.permissions.ShowGridViewAdminToolbar;
        }

        if (!LDH.IsObjectNull(definition.documentEditorDataReceivingLogic) &&
            !LDH.IsValueEmpty(definition.documentEditorDataReceivingLogic) &&
            (LDH.IsValueEmpty(this.documentDataForEditor))) {
            this.documentDataForEditor = JSON.parse(definition.documentEditorDataReceivingLogic);
            this.documentDataString = definition.documentEditorDataReceivingLogic;
        }

        let isExpanded = this.state.isExpandDesigner;
        if (LDH.IsValueEmpty(this.documentDataJSON)) {
            this.documentDataJSON = JSON.parse(definition.documentEditorDataReceivingLogic);
        }

        let dataPreviewerDefaultWidth = 500;
        if (!LDH.IsObjectNull(definition.dataPreviewerDefaultWidth) &&
            !LDH.IsValueEmpty(definition.dataPreviewerDefaultWidth)) {
            dataPreviewerDefaultWidth = definition.dataPreviewerDefaultWidth;
        }

        let dataPreviewerVisible = false;
        if (!LDH.IsObjectNull(definition.dataPreviewerVisibilityByLocation) &&
            !LDH.IsValueEmpty(definition.dataPreviewerVisibilityByLocation) &&
            (definition.dataPreviewerVisibilityByLocation === "dataViewsOnly" ||
                definition.dataPreviewerVisibilityByLocation === "both") && isDataView) {
            dataPreviewerVisible = true;
        } else if (!LDH.IsObjectNull(definition.dataPreviewerVisibilityByLocation) &&
            !LDH.IsValueEmpty(definition.dataPreviewerVisibilityByLocation) &&
            (definition.dataPreviewerVisibilityByLocation === "workspacesOnly" ||
                definition.dataPreviewerVisibilityByLocation === "both") && !isDataView) {
            dataPreviewerVisible = true;
        } else if (LDH.IsObjectNull(definition.dataPreviewerVisibilityByLocation) ||
            LDH.IsValueEmpty(definition.dataPreviewerVisibilityByLocation)) {
            dataPreviewerVisible = true;
        }

        if (dataPreviewerVisible === true) {
            if (!LDH.IsObjectNull(definition.dataPreviewerVisibility) &&
                !LDH.IsValueEmpty(definition.dataPreviewerVisibility) &&
                (definition.dataPreviewerVisibility === "visibleToAdmin" &&
                    window.userRoles === LeopardStaticUIConfig.UserRoleCCAdmin)) {
                dataPreviewerVisible = true;
            } else if (!LDH.IsObjectNull(definition.dataPreviewerVisibility) &&
                !LDH.IsValueEmpty(definition.dataPreviewerVisibility) &&
                definition.dataPreviewerVisibility === "visibleToAll") {
                dataPreviewerVisible = true;
            } else if (LDH.IsObjectNull(definition.dataPreviewerVisibility) ||
                LDH.IsValueEmpty(definition.dataPreviewerVisibility)) {
                dataPreviewerVisible = true;
            } else{
                dataPreviewerVisible = false;
            }
        }

        return (
            <React.Fragment>
                {(!showGridViewAdminToolbar || isExpanded) ? null : this.initializeAdministrativeToolbar()}
                {this.state.isExpandDesigner === false ? "" : this.initializeDocumentDesignerPanel()}
                <div className={"document-editor_widget_" + that.props.dataViewId + " " +
                "leopard-splitter-control " + ((!showGridViewAdminToolbar || isExpanded)
                    ? "" : "with-admin-panel")}>
                    <SplitterLayout
                        secondaryInitialSize={dataPreviewerDefaultWidth}
                        onSecondaryPaneSizeChange={this.onSecondaryPaneSizeChange}>
                        <div className={"leopard-documenteditor-left-hand-side"}>
                            <div style={{display: "flex"}}>
                                <div className={"panel-title"}>Document Editor</div>
                                <div style={{display: "flex"}}>
                                   <span
                                       className={"leopard-loading-icon savedocument document-editor-saveprogress-" + that.props.dataViewId}
                                       style={{marginRight: "5px", marginLeft: "30px", marginTop: "2px"}}>
                                                <i className="fas fa-spinner fa-pulse"
                                                   style={{color: "#FF8100", fontSize: "25px"}}></i>
                                   </span>
                                    <LeopardTooltipWithLink
                                        elementId={"DocumentEditor_DataEditor_Save_" + this.props.dataViewId}
                                        labelText={"Save Document"} refName={"data-document-editor-save"}
                                        refFunc={(e) => this.setUIInstance(e)}
                                        onClick={(e) => this.documentEditorSaveButtonOnClick({e})}>
                                    </LeopardTooltipWithLink>
                                </div>
                            </div>
                            {
                                this.state.isDocumentEditorReadOnly ?
                                    <div className={"ui-disabled-cover"}></div> : null
                            }
                            {
                                this.state.isHideDocumentEditor ? null :
                                    <LeopardDocumentEditorEngine
                                        documentDefinition={definition} documentData={this.documentDataForEditor}
                                        setUIInstance={(e) => that.setUIInstance(e)}
                                        updateDataPreviewerState={(e) => that.updateDataPreviewerState(e)}/>
                            }
                        </div>
                        {
                            dataPreviewerVisible === false ? null :
                                <div className={"leopard-documenteditor-right-hand-side"}>
                                    <div style={{display: "flex"}}>
                                        <div className={"panel-title"}>Data Previewer</div>
                                        {
                                            this.state.isDocumentEditorReadOnly ? null :
                                                <div>
                                                    <LeopardTooltipWithLink
                                                        elementId={"DocumentEditor_DataPreviewer_Edit_" + this.props.dataViewId}
                                                        labelText={"Edit Document"} width={250}
                                                        refName={"data-previewer-edit"}
                                                        refFunc={(e) => this.setUIInstance(e)}
                                                        onClick={(e) => this.documentDataPreviewerEditOnClick({e})}>
                                                    </LeopardTooltipWithLink>
                                                </div>
                                        }
                                        {
                                            !this.state.isDocumentEditorReadOnly ? null :
                                                <div>
                                                    <LeopardTooltipWithLink
                                                        elementId={"DocumentEditor_DataPreviewer_Save_" + this.props.dataViewId}
                                                        labelText={"Save Document"} width={250}
                                                        refName={"data-previewer-save"}
                                                        refFunc={(e) => this.setUIInstance(e)}
                                                        onClick={(e) => this.documentDataPreviewerSaveOnClick({e})}>
                                                    </LeopardTooltipWithLink>
                                                </div>
                                        }
                                        {
                                            !this.state.isDocumentEditorReadOnly ? null :
                                                <div>
                                                    <LeopardTooltipWithLink
                                                        elementId={"DocumentEditor_DataPreviewer_Cancel_" + this.props.dataViewId}
                                                        labelText={"Cancel"} width={250}
                                                        onClick={(e) => this.documentDataPreviewerCancelOnClick({e})}>
                                                    </LeopardTooltipWithLink>
                                                </div>
                                        }
                                    </div>
                                    <div className={"data-previewer-container"}>
                                        <LeopardDocumentPreviewerEngine
                                            ref={(e) => that.setUIInstance({
                                                e: e,
                                                name: "leopard_document_data_previewer"
                                            })}
                                            onDataPreviewerValidated={(e) => that.onDataPreviewerValidated(e)}
                                            documentDataPreviewerValueOnChanged={(e) => that.documentDataPreviewerValueOnChanged(e)}
                                            documentDataJSON={this.documentDataJSON} documentDefinition={definition}>
                                        </LeopardDocumentPreviewerEngine>
                                    </div>
                                </div>
                        }
                    </SplitterLayout>
                </div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardDocumentEditorConfiguration);

import React from 'react';
import {SelectBox} from 'devextreme-react';
import LDH from "../helpers/LeopardDataHelper";
import LRH from "../helpers/LeopardReactHelper";

export class LeopardDocDropdownControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldValue: LDH.IsValueEmpty(props.rootProps.data) ? null : props.rootProps.data.toString(),
            displayName: props.rootProps.schema.field.displayName,
            dataSource: props.rootProps.schema.field.dataSource,
            definition: props.rootProps.config.definition,
            disabled: !LDH.IsObjectNull(props.rootProps.schema.field.disabled) &&
                !LDH.IsValueEmpty(props.rootProps.schema.field.disabled) &&
                props.rootProps.schema.field.disabled === true,
            readOnly: !LDH.IsObjectNull(props.rootProps.schema.field.readOnly) &&
                !LDH.IsValueEmpty(props.rootProps.schema.field.readOnly) &&
                props.rootProps.schema.field.readOnly === true
        };
        this.controlId = "LeopardDocEditor_" + LDH.GenerateGuid();
        this.uiObjectInstances = [];
    }

    fieldValueOnChange = (data) => {
        this.setState({fieldValue: data.e.value}, function () {
            data.props.onValueChanged({value: data.e.value});
        });
    }

    setUIInstance = (data) => {
        if (data.e === undefined || data.e === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.name] = data.e;
    };

    componentWillUnmount = () => {
        let uiObjectInstances = this.uiObjectInstances;
        LRH.DisposeUIInstancesFromList(uiObjectInstances);
    };

    render() {
        let dataSourceToBind = this.state.dataSource;
        let dataKey = "id";
        let dataValue = "name";

        if (typeof this.state.dataSource === "string" && this.state.dataSource.indexOf("{") > -1 &&
            this.state.dataSource.indexOf("}") > -1) {
            if (!LDH.IsObjectNull(this.state.definition.dataSources)) {
                for (let i = 0; i < this.state.definition.dataSources.length; i++) {
                    let dataSource = this.state.definition.dataSources[i];
                    if (!LDH.IsObjectNull(dataSource.dataSourceName) && !LDH.IsValueEmpty(dataSource.dataSourceName) &&
                        this.state.dataSource === "{" + dataSource.dataSourceName + "}") {
                        //TODO: Get data source from server.
                        let dataFromServer = [];

                        let dataShaped = dataFromServer;
                        let dataShapingLogic = dataSource.dataShapingLogic;
                        if (!LDH.IsObjectNull(dataShapingLogic) && !LDH.IsValueEmpty(dataShapingLogic)) {
                            let javascript = dataShapingLogic;
                            let dataName = "data";
                            let dataValue = dataFromServer;
                            dataShaped = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, "");
                        }
                        dataSourceToBind = dataShaped;
                        if (!LDH.IsObjectNull(dataSource.dataKey) && !LDH.IsValueEmpty(dataSource.dataKey)){
                            dataKey = dataSource.dataKey;
                        }
                        if (!LDH.IsObjectNull(dataSource.dataValue) && !LDH.IsValueEmpty(dataSource.dataValue)){
                            dataValue = dataSource.dataValue;
                        }
                    }
                }
            }
        }
        return (
            <React.Fragment>
                <label className={"leopard-doc-editor-label"} htmlFor={this.controlId}>
                    {this.state.displayName}
                </label>
                <SelectBox dataSource={dataSourceToBind} displayExpr={dataValue} valueExpr={dataKey}
                           id={this.controlId} name={this.controlId} value={this.state.fieldValue}
                           className={"leopard-doc-editor-dropdown-control"}
                           disabled={this.state.disabled} readOnly={this.state.readOnly}
                           ref={(e) => this.setUIInstance({e: e, name: this.controlId})}
                           onValueChanged={(e) => this.fieldValueOnChange({e: e, props: this.props})}>
                </SelectBox>
            </React.Fragment>
        )
    }
}

LeopardDocDropdownControl.getDerivedStateFromProps = (nextProps, prevState) => {
    return null;
};
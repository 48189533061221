import React, {Component} from 'react';
import LRH from '../helpers/LeopardReactHelper';
import LeopardGridViewConfiguration from '../components/LeopardGridViewConfiguration';
import LeopardChartConfiguration from '../components/LeopardChartConfiguration';
import LDH from '../helpers/LeopardDataHelper';
import {connect} from 'react-redux';
import $ from 'jquery';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import LeopardReportConfiguration from "../components/LeopardReportConfiguration";
import LeopardMapConfiguration from "../components/LeopardMapConfiguration";
import LeopardDocumentEditorConfiguration from "../components/LeopardDocumentEditorConfiguration";
import LeopardAnalyticsDashboardConfiguration from "../components/LeopardAnalyticsDashboardConfiguration";

class LeopardSingleDataView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnNames: null,
            gridViewName: null,
            mapId: null,
            chartDefinition: null,
            gridViewDefinition: null,
            reportDefinition: null,
            documentDefinition: null,
            analyticsDefinition: null,
            mapDefinition: null,
            styleDefinition: null,
            dataInitializedOnControls: false
        }
    }

    loadGridViewDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let dataViewNote = data.dataViewNote;
            let limitedColumns = [];

            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            for (let i = 0; i < dataViewNote.gridViewDefinition.columnDefinition.length; i++) {
                dataViewNote.gridViewDefinition.columnDefinition[i].hasSorted = false;
                if (!LDH.IsValueEmpty(dataViewNote.gridViewDefinition.columnDefinition[i].isEnabled) &&
                    dataViewNote.gridViewDefinition.columnDefinition[i].isEnabled === false) {
                    limitedColumns.push(dataViewNote.gridViewDefinition.columnDefinition[i].columnName);
                }
            }

            let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
            LRH.ApplyCustomStyle(dataViewNote.styleDefinition, version);
            window.cachedSingleDataViewConfig = LDH.DeepClone(data);

            that.setState({
                gridViewDefinition: dataViewNote.gridViewDefinition,
                styleDefinition: dataViewNote.styleDefinition
            });

            let tableName = data.dataSourceUrl;
            LeopardAjaxHelper.GetDataTableColumnsByDataSourceUrl(tableName, limitedColumns, function (columns) {
                    that.setState({columnNames: columns, gridViewName: data.dataViewName});
                    if (!LDH.IsObjectNull(callback)) callback();
                }, function (error, sessionTimeout) {
                    if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                        error.message.indexOf("Cannot read property '") !== -1 &&
                        error.message.indexOf("' of undefined") !== -1) {
                        LRH.ShowToast("Your Data View might have been deleted or moved. " +
                            "Please refresh this page to update the Control Centre.", "error", 5000);
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve data for your Data View. " +
                            "Please check your network connection status and try again.", "error", 5000);
                        localStorage.setItem("LoadFirstMenuItem", "false");
                    }
                    LRH.ShowOrHideMenuLoadingProgress(false);
                    LRH.ShowUnhandledExceptionForMasterContent();
                }, data.dataViewNote.gridViewDefinition.dataSourceCustomQueryTableSchema,
                data.dataViewNote.gridViewDefinition.customComputedColumns);
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadAnalyticsDashboardDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);
            window.cachedSingleDataViewConfig = LDH.DeepClone(data);

            that.setState({analyticsDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadReportDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);
            window.cachedSingleDataViewConfig = LDH.DeepClone(data);

            that.setState({reportDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadDocumentEditorDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);
            window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            that.setState({documentDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadMapDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);
            window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            that.setState({mapDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadChartDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            let dataViewNote = data.dataViewNote;
            window.cachedSingleDataViewConfig = LDH.DeepClone(data);

            that.setState({chartDefinition: dataViewNote});
            if (!LDH.IsObjectNull(callback)) callback();
        });
    };

    updateGridViewDefinitionOnSingleDataView = (definition) => {
        this.setState({gridViewDefinition: definition});
    };

    dataInitializedOnControlsUpdateRequest = () => {
        this.setState({dataInitializedOnControls: true});
    };

    componentDidMount = () => {
        let that = this;
        LeopardStaticUIConfig.Global_DashboardDataViewListeners = [];

        if (this.props.dataViewType === "datagrid") {
            window.Global_TempDateConvertion = [];
            this.loadGridViewDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "chart") {
            this.loadChartDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "report") {
            this.loadReportDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "aws-quicksight-dashboard") {
            this.loadAnalyticsDashboardDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "document-editor") {
            this.loadDocumentEditorDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "map") {
            this.loadMapDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }
    };

    componentWillUnmount = () => {
        window.cachedSingleDataViewConfig = null;
    };

    chartDefinitionUpdateForPreview = (data) => {
        let definitionClone = LDH.DeepClone(this.state.chartDefinition);
        definitionClone.chartDefinition = data.definition;

        this.setState({chartDefinition: definitionClone});
    };

    renderLoadingProgressIndicator = () => {
        LRH.ShowOrHideMenuLoadingProgress(true);
        return (
            <React.Fragment>
                <span className={"leopard-loading-icon"} style={{width: "100%", height: "100%", marginLeft: "0px"}}>
                  <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
                  <span className="leopard-largeloading-progress-text">Loading data...</span>
                </span>
            </React.Fragment>
        );
    };

    render() {
        if (this.props.dataViewType === "datagrid") {
            if (LDH.IsObjectNull(this.state.columnNames) || LDH.IsObjectNull(this.state.columnNames.fullColumns) ||
                LDH.IsObjectNull(this.props.dataViewId) || LDH.IsObjectNull(this.state.gridViewName)) {
                return this.renderLoadingProgressIndicator();
            }
            let gridViewDefinition = this.state.gridViewDefinition;
            let styleDefinition = this.state.styleDefinition;
            let displayAttr = this.state.dataInitializedOnControls ? "block" : "none";
            let dataSourceId = this.props.dataSourceUrl;

            if (!LDH.IsObjectNull(gridViewDefinition.overrideDatabaseTableField) &&
                !LDH.IsValueEmpty(gridViewDefinition.overrideDatabaseTableField)) {
                dataSourceId = gridViewDefinition.overrideDatabaseTableField;
            }

            if (!LDH.IsObjectNull(gridViewDefinition.extraQueryForSingleDataView) &&
                !LDH.IsValueEmpty(gridViewDefinition.extraQueryForSingleDataView)) {
                dataSourceId += gridViewDefinition.extraQueryForSingleDataView;
            }

            return (
                <React.Fragment>
                    {displayAttr === "none" ? this.renderLoadingProgressIndicator() : ""}
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{display: displayAttr, backgroundColor: "white"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardGridViewConfiguration
                            dataSourceUrl={dataSourceId}
                            viewOptionsText={this.state.gridViewName}
                            columnFieldList={this.state.columnNames}
                            gridViewName={this.state.gridViewName}
                            windowHeight={this.props.windowHeight}
                            setGridViewInstance={this.props.setGridViewInstance}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                            dataInitializedOnControlsUpdateRequest={(e) => this.dataInitializedOnControlsUpdateRequest(e)}
                            dataInitializedOnControls={this.state.dataInitializedOnControls}
                            updateGridViewDefinitionOnSingleDataView={(e) => this.updateGridViewDefinitionOnSingleDataView(e)}
                            gridViewDefinition={gridViewDefinition}
                            styleDefinition={styleDefinition}
                            gridViewId={this.props.dataViewId}/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "report") {
            if (LDH.IsObjectNull(this.props.dataViewId) ||
                LDH.IsObjectNull(this.state.reportDefinition)) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId} style={{backgroundColor: "white"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardReportConfiguration
                            dataViewId={this.props.dataViewId}
                            reportDefinition={this.state.reportDefinition}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "aws-quicksight-dashboard") {
            if (LDH.IsObjectNull(this.props.dataViewId) ||
                LDH.IsObjectNull(this.state.analyticsDefinition)) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId} style={{backgroundColor: "white", height: "100%"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardAnalyticsDashboardConfiguration
                            dataViewId={this.props.dataViewId}
                            analyticsDefinition={this.state.analyticsDefinition}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "document-editor") {
            if (LDH.IsObjectNull(this.props.dataViewId) ||
                LDH.IsObjectNull(this.state.documentDefinition)) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{backgroundColor: "white", height: "100%"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardDocumentEditorConfiguration
                            viewType={"dataview"} dataViewId={this.props.dataViewId} isDataView={true}
                            documentDefinition={this.state.documentDefinition}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "photo") {
            if (this.props.dataViewId === null || this.state.photoDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div style={{textAlign: "center", marginTop: "15%", fontFamily: "Arial", fontSize: "20px"}}>
                        <i className="far fa-meh" style={{color: "#FF8200"}}></i>
                        <div className={"leopard-empty-dataview-warning"}>
                            This Data View cannot be used on its own. It must be linked with other Data View in a
                            Workspace.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "notepad") {
            if (this.props.dataViewId === null || this.state.notepadDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div style={{textAlign: "center", marginTop: "15%", fontFamily: "Arial", fontSize: "20px"}}>
                        <i className="far fa-meh" style={{color: "#FF8200"}}></i>
                        <div className={"leopard-empty-dataview-warning"}>
                            This Data View cannot be used on its own. It must be linked with other Data View in a
                            Workspace.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "map") {
            if (this.props.dataViewId === null || this.state.mapDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{backgroundColor: "white", height: "100%"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardMapConfiguration
                            viewType={"dataview"} dataViewId={this.props.dataViewId} isDataView={true}
                            mapDefinition={this.state.mapDefinition}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "chart") {
            if (this.props.dataViewId === undefined || this.props.dataViewId === null ||
                this.state.chartDefinition === undefined || this.state.chartDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }

            let hasChartDataReceived = false;
            if (!LDH.IsObjectNull(this.props.state.chartState)) {
                for (let i = 0; i < this.props.state.chartState.length; i++) {
                    if (this.props.state.chartState[i].dataViewId === this.props.dataViewId &&
                        !LDH.IsObjectNull(this.props.state.chartState[i].chartData) &&
                        Array.isArray(this.props.state.chartState[i].chartData)) {
                        hasChartDataReceived = true;
                    }
                }
            }
            let displayAttr = this.state.dataInitializedOnControls && hasChartDataReceived ? "block" : "none";

            return (
                <React.Fragment>
                    {displayAttr === "none" ? this.renderLoadingProgressIndicator() : ""}
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{display: displayAttr, backgroundColor: "white"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardChartConfiguration
                            dataSourceUrl={this.props.dataSourceUrl}
                            palette={['#c3a2cc', '#b7b5e0', '#e48cba']}
                            customizeTextYAxis={"#value#"}
                            definition={this.state.chartDefinition}
                            dataViewId={this.props.dataViewId}
                            chartDefinitionUpdateForPreview={(e) => this.chartDefinitionUpdateForPreview(e)}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                            dataInitializedOnControlsUpdateRequest={(e) => this.dataInitializedOnControlsUpdateRequest(e)}
                            dataInitializedOnControls={this.state.dataInitializedOnControls}
                            setChartInstance={this.props.setChartInstance}
                            chartType={this.state.chartDefinition.chartType}/>
                    </div>
                </React.Fragment>
            );
        }
        return (<React.Fragment>
            <div>Data not found</div>
        </React.Fragment>);
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardSingleDataView);

import React, {Component} from 'react';
import 'ol/ol.css';
import "ol-ext/dist/ol-ext.css";
import $ from "jquery";

import LDH from '../helpers/LeopardDataHelper';
import LOLH from "../helpers/LeopardOpenLayersHelper";
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import LeopardCountdownSwitch from "../datashaping/LeopardCountdownSwitch";
import LRH from "../helpers/LeopardReactHelper";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import {Popover} from 'devextreme-react/popover';
import ScrollView from 'devextreme-react/scroll-view';
import LWH from "../helpers/LeopardWebsocketHelper";

class LeopardMapEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mapInstance: null
        };

        this.selectedParentViewData = null;
        this.popoverContent = [];
        this.uiObjectInstances = [];
        this.mapDefinition = [];
        this.dataViewId = null;
        this.relationshipsLinkedToDataView = [];
        this.pendingWebsocketCallbacks = [];
    }

    componentDidMount() {
        let that = this;
        this.mapDefinition = this.props.mapDefinition;
        this.dataViewId = this.props.dataViewId;

        if (LDH.IsObjectNull(this.mapDefinition.mapProvider) ||
            LDH.IsValueEmpty(this.mapDefinition.mapProvider) ||
            this.mapDefinition.mapProvider === "google-map") {
            this.initializeGoogleMap();
        }
        if (this.mapDefinition.mapProvider === "open-street-map") {
            this.initializeOpenStreetMap();
        }
        this.establishRelationships();

        $("#leopard-map-control-" + this.dataViewId).resize(function () {
            if (!LDH.IsObjectNull(that.state.mapInstance) &&
                !LDH.IsObjectNull(that.state.mapInstance.ol3map)) {
                that.state.mapInstance.ol3map.updateSize();
            }
        });
    }

    componentWillUnmount() {
        if (LDH.IsObjectNull(window.leopardMapInstances)) {
            window.leopardMapInstances = [];
        }
        for (let i = 0; i < window.leopardMapInstances.length; i++) {
            if (LDH.IsObjectNull(window.leopardMapInstances[i])) {
                continue;
            }
            let id = window.leopardMapInstances[i].dataViewId;
            if (id === this.dataViewId) {
                delete window.leopardMapInstances[i];
                break;
            }
        }
        LWH.RemovePendingEventByDataViewId(this.dataViewId);
        for (let i = 0; i < this.pendingWebsocketCallbacks.length; i++) {
            clearInterval(this.pendingWebsocketCallbacks[i]);
        }
    }

    setObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) ||
            !LDH.IsObjectNull(this.uiObjectInstances[data.controlName])) {
            return false;
        }
        this.uiObjectInstances[data.controlName] = data.ref;
        return true;
    };

    setMapInstance = (thisComp, dataObj) => {
        if (!thisComp.setObjectInstance(dataObj)) {
            return;
        }
        if (LDH.IsObjectNull(window.leopardMapInstances)) {
            window.leopardMapInstances = [];
        }

        let mapFound = false;
        let instanceIndex = null;
        for (let i = 0; i < window.leopardMapInstances.length; i++) {
            if (LDH.IsObjectNull(window.leopardMapInstances[i])) {
                continue;
            }
            let id = window.leopardMapInstances[i].dataViewId;
            if (id === thisComp.props.dataViewId) {
                instanceIndex = i;
                mapFound = true;
                break;
            }
        }
        if (mapFound === false) {
            window.leopardMapInstances.push({
                instance: dataObj.ref,
                dataViewId: thisComp.props.dataViewId
            });
        } else {
            window.leopardMapInstances[instanceIndex] = {
                instance: dataObj.ref,
                dataViewId: thisComp.props.dataViewId
            }
        }

        if (!LDH.IsObjectNull(thisComp.props.setMapInstance)) {
            thisComp.props.setMapInstance({
                instance: dataObj.ref,
                id: thisComp.props.dataViewId,
                type: "map"
            });
        }
    }

    establishRelationships = () => {
        let that = this;
        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: "blank",
                callback(data) {
                    if (!LDH.IsObjectNull(that.props.mapDefinition.parameters) &&
                        !LDH.IsValueEmpty(that.props.mapDefinition.parameters)) {
                        let parametersCloned = LDH.DeepClone(that.props.mapDefinition.parameters);

                        for (let x = 0; x < parametersCloned.length; x++) {
                            let item = parametersCloned[x];
                            if (!LDH.IsObjectNull(window["dataViewParam_" + that.props.dataViewId +
                            "_control_" + item.parameterName])) {
                                let controlId = window["dataViewParam_" + that.props.dataViewId +
                                "_control_" + item.parameterName];
                                data.dataFromSource[item.parameterName] = controlId;
                            }
                        }
                    }

                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let mapDefinition = that.props.mapDefinition;
                            let params = mapDefinition.oDataQueryForLinkedView;
                            that.selectedParentViewData = data.dataFromSource;

                            if (!LDH.IsObjectNull(params) && !LDH.IsValueEmpty(params)) {
                                if (!LDH.IsObjectNull(data.dataFromSource)) {
                                    params = LDH.ConvertArrayMacroToString(params, data.dataFromSource, null);

                                    let method = "get";
                                    if (!LDH.IsObjectNull(mapDefinition.httpMethodForLinkedView) &&
                                        !LDH.IsValueEmpty(mapDefinition.httpMethodForLinkedView)) {
                                        method = mapDefinition.httpMethodForLinkedView;
                                    }
                                    LeopardAjaxHelper.ApiGatewayInvoker(method, params, null, function (response) {
                                        if (!LDH.IsObjectNull(mapDefinition.postDataStriptForLinkedView) &&
                                            !LDH.IsValueEmpty(mapDefinition.postDataStriptForLinkedView)) {
                                            let javascript = mapDefinition.postDataStriptForLinkedView;
                                            let dataName = "parentRowData";
                                            let dataValue = response;
                                            let mapControl = that.state.mapInstance.ol3map;
                                            let gpsList = LDH.EvaluateJavaScriptForDataShaping(javascript,
                                                dataName, dataValue, that.props.dataViewId);

                                            if (LDH.IsObjectNull(gpsList)) gpsList = [];
                                            that.displayRoutesOnMap_ORS(mapControl, "driving-car",
                                                gpsList, that.props.dataViewId, mapDefinition,
                                                {parentRowData: data.dataFromSource}, true);
                                        }
                                    }, function (error, errorHandler) {

                                    }, null, null, false);
                                }
                            }
                        }
                    }
                }
            });
        }
    };

    initializeMapEvents = () => {
        let that = this;
        this.state.mapInstance.ol3map.on("moveend", function () {
            let popupControl = that.uiObjectInstances["popoverControl"];
            if (LDH.IsObjectNull(popupControl) ||
                LDH.IsObjectNull(popupControl.instance)) {
                return;
            }
            popupControl.instance.repaint();
        });
    }

    initializeGoogleMap = () => {
        let that = this;
        let provider = LeopardStaticUIConfig.MapProviderGoogle;
        let viewOptions = {center: [0, 0], zoom: 0, maxZoom: 22, minZoom: 0};
        let elemName = "leopard-map-control-" + this.dataViewId;
        let mapInstance = LOLH.InitializeMap(provider, viewOptions, elemName);
        this.setState({mapInstance: mapInstance}, function () {
            that.state.mapInstance.activate();
            that.setMapInstance(that, {
                ref: that.state.mapInstance.ol3map,
                controlName: "mapControl"
            });
            that.initializeMapEvents();
        });
    };

    initializeOpenStreetMap = () => {
        let that = this;
        let provider = LeopardStaticUIConfig.MapProviderOpenStreetMap;
        let viewOptions = {center: [0, 0], zoom: 0, maxZoom: 22, minZoom: 0};
        let elemName = "leopard-map-control-" + this.dataViewId;
        let ol3map = LOLH.InitializeMap(provider, viewOptions, elemName);

        let mapInstance = new Object();
        mapInstance.ol3map = ol3map;
        this.setState({mapInstance: mapInstance}, function () {
            that.setMapInstance(that, {
                ref: that.state.mapInstance.ol3map,
                controlName: "mapControl"
            });
            that.initializeMapEvents();
        });
    };

    displayRoutesOnMap_ORS = (mapControl, routeType, pointCoords, dataViewId, mapDefinition, parentData, refreshExtentOnLoad) => {
        let that = this;
        if (LDH.IsObjectNull(pointCoords) || pointCoords.length === 0) {
            LOLH.ClearRoutesOnMap(mapControl);
            LOLH.ClearOverlaysOnMap(mapControl);
            return;
        }

        if (pointCoords.length === 1) {
            LOLH.ClearRoutesOnMap(mapControl);
            LOLH.ClearOverlaysOnMap(mapControl);

            let coords3857 = LOLH.ConvertCoordsToEPSG3857(pointCoords[0].Gps);
            LOLH.CreateMarker(that.mapDefinition, coords3857, mapControl, dataViewId, 0,
                pointCoords.length, that.uiObjectInstances, parentData, that, pointCoords[0]);
            LOLH.CenterMapByCoords(mapControl, coords3857);
            return;
        }

        let url = LDH.ReplaceAll(window.ORS_Api_DirectionUrl, "{0}", routeType);
        let coordsArray = [];
        let pointCount = 0;
        for (let i = 0; i < pointCoords.length; i++) {
            if (pointCount >= 50) break;
            if (LDH.IsObjectNull(pointCoords[i].Gps)) {
                continue;
            }
            coordsArray.push(pointCoords[i].Gps);
            pointCount++;
        }

        let body = JSON.stringify({"coordinates": coordsArray});
        let headers = [
            {
                name: "Accept",
                value: "application/json, application/geo+json, application/gpx+xml, " +
                    "img/png; charset=utf-8"
            },
            {name: "Content-Type", value: "application/json"},
            {name: "Authorization", value: LeopardStaticUIConfig.OpenRouteServiceAPIKey}
        ];

        $("#gridViewToobar_" + dataViewId).show();
        $("#Map_TopBar_Refresh_" + dataViewId).addClass("leopard-ui-disabled");

        LOLH.ClearOverlaysOnMap(mapControl);
        for (let i = 0; i < pointCoords.length; i++) {
            if (LDH.IsObjectNull(pointCoords[i].Gps)) {
                continue;
            }
            let coords3857 = LOLH.ConvertCoordsToEPSG3857(pointCoords[i].Gps);
            LOLH.CreateMarker(that.mapDefinition, coords3857, mapControl, dataViewId, i,
                pointCoords.length, that.uiObjectInstances, parentData, that, pointCoords[i]);
        }

        LeopardAjaxHelper.GenericXMLHttpRequest("post", url, body, function (responseData) {
            try {
                LOLH.ClearRoutesOnMap(mapControl);

                let jsonData = JSON.parse(responseData);
                LOLH.AddMapLayerByGeoJSON(jsonData, mapControl, that.mapDefinition);

                if (LDH.IsObjectNull(refreshExtentOnLoad) || refreshExtentOnLoad) {
                    $("#map_elements_cache_" + dataViewId).empty();
                    let features = LOLH.GetFeatureByGeoJSON(jsonData, "EPSG:3857");
                    LOLH.SetMapExtentByFeature(mapControl, features[0], mapDefinition);
                }
                $("#gridViewToobar_" + dataViewId).hide();
                $("#Map_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
            } catch (ex) {
                LRH.ShowToast("Failed to retrieve map data.", "error", 5000);
                $("#gridViewToobar_" + dataViewId).hide();
                $("#Map_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                console.log("error", ex);
            }
        }, function (error) {
            LRH.ShowToast("Failed to retrieve map data.", "error", 5000);
            $("#gridViewToobar_" + dataViewId).hide();
            $("#Map_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
            console.log("error", error);
        }, "open-route-service", headers);
    };

    autoRefreshCountdownOnEnd = () => {
        this.refreshOnClick();
    };

    refreshOnClick = () => {
        let that = this;
        let mapDefinition = this.mapDefinition;
        let params = mapDefinition.oDataQueryForLinkedView;
        let parentData = this.selectedParentViewData;
        let dataViewId = this.props.dataViewId;

        if ($("#Map_TopBar_Refresh_" + dataViewId).hasClass("leopard-ui-disabled")) {
            return;
        }

        if (!LDH.IsObjectNull(params) && !LDH.IsValueEmpty(params)) {
            if (!LDH.IsObjectNull(parentData)) {
                params = LDH.ConvertArrayMacroToString(params, parentData, null);

                let method = "get";
                if (!LDH.IsObjectNull(mapDefinition.httpMethodForLinkedView) &&
                    !LDH.IsValueEmpty(mapDefinition.httpMethodForLinkedView)) {
                    method = mapDefinition.httpMethodForLinkedView;
                }
                LeopardAjaxHelper.ApiGatewayInvoker(method, params, null, function (response) {
                    if (!LDH.IsObjectNull(mapDefinition.postDataStriptForLinkedView) &&
                        !LDH.IsValueEmpty(mapDefinition.postDataStriptForLinkedView)) {
                        let javascript = mapDefinition.postDataStriptForLinkedView;
                        let dataName = "parentRowData";
                        let dataValue = response;
                        let mapControl = that.state.mapInstance.ol3map;
                        let gpsList = LDH.EvaluateJavaScriptForDataShaping(javascript,
                            dataName, dataValue, dataViewId);

                        if (LDH.IsObjectNull(gpsList)) gpsList = [];
                        that.displayRoutesOnMap_ORS(mapControl, "driving-car",
                            gpsList, dataViewId, mapDefinition,
                            {parentRowData: parentData}, false);
                    }
                }, function (error, errorHandler) {

                }, null, null, false);
            }
        }
    };

    mapToolbar = (mapDefinition) => {
        let that = this;

        let enableAutoRefresh = false;
        if (!LDH.IsObjectNull(mapDefinition.enableAutoRefresh) &&
            !LDH.IsValueEmpty(mapDefinition.enableAutoRefresh)) {
            enableAutoRefresh = mapDefinition.enableAutoRefresh;
        }

        let autoRefreshInterval = 30;
        if (!LDH.IsObjectNull(mapDefinition.autoRefreshInterval) &&
            !LDH.IsValueEmpty(mapDefinition.autoRefreshInterval)) {
            autoRefreshInterval = mapDefinition.autoRefreshInterval;
        }

        let showAutoRefreshSwitch = true;
        if (!LDH.IsObjectNull(mapDefinition.showAutoRefreshSwitch) &&
            !LDH.IsValueEmpty(mapDefinition.showAutoRefreshSwitch)) {
            showAutoRefreshSwitch = mapDefinition.showAutoRefreshSwitch;
        }

        return (
            <React.Fragment>
                <div className={"leopard-map-toolbar"} style={{minHeight: "30px"}}>
                    {
                        enableAutoRefresh === false ? "" :
                            <span id={"autoRefresh_" + that.props.dataViewId}
                                  className={"leopard-autorefresh-button_wrapper"}
                                  style={{display: showAutoRefreshSwitch ? "block" : "none"}}>
                                     <div id={"autoRefreshCountdownControl_" + that.props.dataViewId}>
                                        <LeopardCountdownSwitch
                                            autoRefreshCountdownOnEnd={that.autoRefreshCountdownOnEnd}
                                            tooltip={"The map will be refreshed automatically when timer counts down to 0."}
                                            autoRefreshInterval={autoRefreshInterval}
                                            fieldValue={enableAutoRefresh} gridViewId={that.props.dataViewId}/>
                                     </div>
                                </span>
                    }
                    {
                        LRH.RenderDataViewParameters(mapDefinition.parameters, that.props.dataViewId,
                            mapDefinition, function (data) {
                                window[data.controlName] = data.control.value;
                                if (!LDH.IsObjectNull(data.dataShapingForQuery) &&
                                    !LDH.IsValueEmpty(data.dataShapingForQuery)) {
                                    let javascript = data.dataShapingForQuery;
                                    let dataName = "data";
                                    let dataValue = [];
                                    LDH.EvaluateJavaScriptForDataShaping(javascript,
                                        dataName, dataValue, that.props.dataViewId);
                                }

                                if (data.autoApplyParameterFilter) {
                                    // that.refreshOnClick({
                                    //     gridViewId: that.props.dataViewId,
                                    //     refreshChildViews: true
                                    // });
                                }
                            })
                    }
                    <span id={"gridViewToobar_" + that.props.dataViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{color: "#FF8100", fontSize: "18px"}}></i>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                elementId={"Map_TopBar_Refresh_" + that.props.dataViewId}
                                labelText={"Refresh"} width={250} title={"Refresh"}
                                onClick={(e) => this.refreshOnClick({e: e})}
                                text={"The Refresh button allows you to refresh data and repaint the map."}/>
                        </span>
                </div>
            </React.Fragment>
        )
    };

    onPopoverShowing = (e) => {
        let that = this;
        let popoverInstance = that.uiObjectInstances["popoverControl"].instance;
        let content = popoverInstance.content();
        let popoverId = LDH.GenerateGuid();
        $(".leopard-table-body", content).attr("id", popoverId);

        let progress = "<tr><td><span class='leopard-gridview-dataloading' style='display: block;padding-bottom: 5px;margin: 0;'>" +
            "<i class='fas fa-spinner fa-pulse' style='color: rgb(255, 128, 0);font-size: 25px;'></i></span></td></tr>";

        $("#" + popoverId, content).html(progress);
        popoverInstance.option("closeOnOutsideClick", false);

        let htmlData = "";
        $("#Map_TopBar_Refresh_" + that.props.dataViewId).addClass("leopard-ui-disabled");

        if (!LDH.IsObjectNull(that.mapDefinition.oDataTableForMarkerTooltip) &&
            !LDH.IsValueEmpty(that.mapDefinition.oDataTableForMarkerTooltip) &&
            !LDH.IsValueEmpty(that.mapDefinition.oDataQueryForMarkerTooltip) &&
            !LDH.IsValueEmpty(that.mapDefinition.oDataQueryForMarkerTooltip)) {
            let eventKey = LDH.GenerateGuid();
            let params = that.mapDefinition.oDataQueryForMarkerTooltip;
            let pointData = popoverInstance.option("pointData");

            params = LDH.ConvertArrayMacroToString(params, pointData, null);
            LWH.AddPendingEvent(eventKey, popoverId, that.dataViewId, "leopard-map-tooltip-request");

            let messageTemplate = LWH.GetMessageTemplate(
                "leopardsystems.tooltip.request", window.websocketConnectionId, {
                    "entity": that.mapDefinition.oDataTableForMarkerTooltip, "query": params
                }, eventKey
            );
            LWH.SendMessage(JSON.stringify(messageTemplate));

            let pendingCallback = LWH.WaitForWebsocketResponse(eventKey, function (response, responseData) {
                if (response === "aborted") {
                    popoverInstance.option("closeOnOutsideClick", true);
                    $("#Map_TopBar_Refresh_" + that.dataViewId).removeClass("leopard-ui-disabled");
                } else if (response === "completed") {
                    if (LDH.IsObjectNull(responseData)) return;

                    if (!LDH.IsObjectNull(that.mapDefinition.postDataStriptForMarkerTooltip) &&
                        !LDH.IsValueEmpty(that.mapDefinition.postDataStriptForMarkerTooltip)) {
                        let javascript = that.mapDefinition.postDataStriptForMarkerTooltip;
                        let dataName = "data";
                        let dataValue = responseData;
                        responseData = LDH.EvaluateJavaScriptForDataShaping(javascript,
                            dataName, dataValue, that.dataViewId);
                    }

                    let keys = Object.keys(responseData);
                    let popoverContentData = [];
                    for (let v = 0; v < keys.length; v++) {
                        if (LDH.IsObjectNull(responseData[keys[v]])) {
                            continue;
                        }
                        if (LDH.IsValueEmpty(responseData[keys[v]])) {
                            continue;
                        }
                        popoverContentData.push({
                            id: keys[v].replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                            value: responseData[keys[v]]
                        })
                    }
                    that.popoverContent = popoverContentData;

                    for (let i = 0; i < that.popoverContent.length; i++) {
                        let item = that.popoverContent[i];
                        htmlData +=
                            "<tr>" +
                            "   <td class='leopard-map-marker-popover-contentcell-id'>" +
                            "        <div class='leopard-map-marker-popover-contentcell-id-text' " +
                            "             title='" + item.id + "'>" + item.id + "</div>" +
                            "   </td>" +
                            "   <td class='leopard-map-marker-popover-contentcell-value'>" +
                            "        <div class='leopard-map-marker-popover-contentcell-value-text' " +
                            "             title='" + item.value + "'>" + item.value + "</div>" +
                            "   </td>" +
                            "</tr>";
                    }
                    if ($("#" + popoverId, content).length > 0) {
                        $("#" + popoverId, content).html(htmlData);
                    }
                    popoverInstance.repaint();
                    popoverInstance.option("closeOnOutsideClick", true);
                    $("#Map_TopBar_Refresh_" + that.dataViewId).removeClass("leopard-ui-disabled");
                }
            });
            that.pendingWebsocketCallbacks.push(pendingCallback);
        }
    };

    onPopoverHidden = (e) => {
        let that = this;
        let content = that.uiObjectInstances["popoverControl"].instance.content();
        $(".leopard-table-body", content).attr("id", "").html("");
    };

    popoverContentRender = (e) => {
        return (<React.Fragment>
            <ScrollView showScrollbar={"onHover"}>
                <table>
                    <tbody className={"leopard-table-body"}></tbody>
                </table>
            </ScrollView>
        </React.Fragment>)
    };

    render() {
        return (
            <React.Fragment>
                {this.mapToolbar(this.mapDefinition)}
                <div className={"leopard-map-container"}>
                    <div id={"leopard-map-control-" + this.props.dataViewId}
                         className={"leopard-map-control"}></div>
                </div>
                <div style={{display: "none"}}>
                    <div id={"map_marker_template_" + this.dataViewId}>
                        <i className={"fas fa-map-marker leopard-map-marker-container"}>
                            <div className={"leopard-map-marker-text"}></div>
                        </i>
                    </div>
                    <div id={"map_elements_cache_" + this.dataViewId}></div>
                </div>
                <Popover position="top" Width={400} maxHeight={300} ref={(e) => this.setObjectInstance({
                    controlName: "popoverControl", ref: e
                })} onShowing={this.onPopoverShowing} onHidden={this.onPopoverHidden}
                         contentRender={this.popoverContentRender}>
                </Popover>
            </React.Fragment>
        );
    }
}

export default LeopardMapEngine;
import React, {Component} from 'react';
import LeopardColumnDesigner from '../datashaping/LeopardColumnDesigner';
import LeopardStyleBuilder from '../datashaping/LeopardStyleBuilder';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import $ from 'jquery';
import LDH from "../helpers/LeopardDataHelper";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardSecurity from "../security/LeopardSecurity";

class LeopardGridViewAdministrativeTools extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.state = {
            dataSourceUrl: null,
            showGridViewBuilder: false,
            showStyleBuilder: false,
            allowEditingGridView: false,
            allowDraggingGridView: false,
            allowDroppingGridView: false,
            filterBuilderValueGridView: "",
            columnResizingModeGridView:
            LeopardStaticUIConfig.GridView_ColumnResizingMode
        }
    }

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    administrativeToolbarButtonOnClick = (data, callback) => {
        if (LeopardSecurity.ShowErrorMessageForLeopardAdminFeature(this)) {
            return;
        }
        $("#gridViewAdminToolbar_" + data.gridViewId).show();
        $("#GridView_Admin_EditColumns_" + data.gridViewId).addClass("leopard-ui-disabled");
        $("#GridView_Admin_EditStyle_" + data.gridViewId).addClass("leopard-ui-disabled");
        if (callback !== undefined && callback !== null) callback();
    };

    gridViewBuilderButtonOnClick = (data) => {
        let that = this;
        this.administrativeToolbarButtonOnClick(data, function(){
            setTimeout(() => {
                that.setState({showGridViewBuilder: true});
            }, 100);
        });
    };

    styleBuilderButtonOnClick = (data) => {
        let that = this;
        this.administrativeToolbarButtonOnClick(data, function(){
            setTimeout(() => {
                that.setState({showStyleBuilder: true});
            }, 100);
        });
    };

    styleBuilderCancelButtonOnClick = () => {
        this.setState({showGridViewBuilder: false});
        this.setState({showStyleBuilder: false});
    };

    gridViewBuilderCancelButtonOnClick = () => {
        this.setState({showGridViewBuilder: false});
        this.setState({showStyleBuilder: false});
    };

    // =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    initializeGridViewColumnDesigner() {
        return <div className="leopard-column-designer" custom_attr_gridviewid={this.props.gridViewId}>
            <LeopardColumnDesigner
                gridViewId={this.props.gridViewId}
                columnFieldList={this.props.columnFieldList}
                gridViewDefinition={this.props.gridViewDefinition}
                gridViewBuilderVisible={this.state.showGridViewBuilder}
                gridViewBuilderCancelButtonOnClick={() => this.gridViewBuilderCancelButtonOnClick()}
                applyGridViewConfigurationOnDataView={this.props.applyGridViewConfigurationOnDataView}
                updateGridViewDefinitionOnSingleDataView={this.props.updateGridViewDefinitionOnSingleDataView}/>
        </div>;
    }

    initializeGridViewStyleBuilder() {
        return <div className="leopard-style-builder" custom_attr_gridviewid={this.props.gridViewId}>
            <LeopardStyleBuilder
                gridViewId={this.props.gridViewId}
                styleBuilderVisible={this.state.showStyleBuilder}
                custom_attr_gridviewid={this.props.gridViewId}
                gridViewDefinition={this.props.gridViewDefinition}
                styleBuilderCancelButtonOnClick={() => this.styleBuilderCancelButtonOnClick()}
                styleDefinition={this.props.styleDefinition}/>
        </div>;
    }

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        return (
            <React.Fragment>
                {
                    <RenderLeopardGridViewAdministrativeToolbar
                        gridViewId={this.props.gridViewId}
                        gridViewBuilderVisible={this.state.showGridViewBuilder}
                        styleBuilderVisible={this.state.showStyleBuilder}
                        parentComp={this.props.parentComp} dataSourceUrl={this.props.dataSourceUrl}
                        styleBuilderButtonOnClick={(e) => this.styleBuilderButtonOnClick(e)}
                        gridViewBuilderButtonOnClick={(e) => this.gridViewBuilderButtonOnClick(e)}
                    />
                }
                {!this.state.showGridViewBuilder ? "" : this.initializeGridViewColumnDesigner()}
                {!this.state.showStyleBuilder ? "" : this.initializeGridViewStyleBuilder()}
            </React.Fragment>
        );
    }
}

const RenderLeopardGridViewAdministrativeToolbar = (
    {
        gridViewId, gridViewBuilderButtonOnClick, styleBuilderButtonOnClick,
        gridViewBuilderVisible, parentComp, styleBuilderVisible, dataSourceUrl
    }) => {
    if (LDH.IsValueEmpty(dataSourceUrl) === true) return null;
    let isBothHidden = !gridViewBuilderVisible && !styleBuilderVisible;
    let showGridViewAdminToolbar = false;
    if (parentComp.props.state.permissions !== undefined && parentComp.props.state.permissions !== null) {
        showGridViewAdminToolbar = parentComp.props.state.permissions.ShowGridViewAdminToolbar;
    }
    return (
        <React.Fragment>
            {showGridViewAdminToolbar === false || isBothHidden === false ? "" :
                (<div className={"leopard-no-whitespace"}>
                        <span style={{padding: "0 10px 0 15px", display: "inline"}}>
                            <LeopardTooltipWithLink
                                elementId={"GridView_Admin_EditColumns_" + gridViewId}
                                labelText={"Configure Grid"} width={250} title={"Configure Grid"}
                                additionalClass={"leopard-gridview-admin-toolbar"}
                                onClick={(e) => gridViewBuilderButtonOnClick({e, gridViewId})}
                                text={"This feature allows admin users to configure the Data Grid settings."}>
                            </LeopardTooltipWithLink>
                        </span>
                    <span style={{padding: "0 0 0 5px", display: "inline"}}>
                            <LeopardTooltipWithLink
                                elementId={"GridView_Admin_EditStyle_" + gridViewId}
                                labelText={"Edit Style"} width={250} title={"Edit Style"}
                                additionalClass={"leopard-gridview-admin-toolbar"}
                                onClick={(e) => styleBuilderButtonOnClick({e, gridViewId})}
                                text={"This feature allows admin users to change the look-and-feel of " +
                                "Data Grid by adding custom CSS."}>
                            </LeopardTooltipWithLink>
                        </span>
                    <span id={"gridViewAdminToolbar_" + gridViewId} className="leopard-admintoolbar-loading">
                            <i className="fas fa-spinner fa-pulse" style={{
                                color: "rgb(255, 128, 0)", fontSize: "18px"
                            }}></i>
                        </span>
                </div>)
            }
        </React.Fragment>
    );
};

export default LeopardGridViewAdministrativeTools;

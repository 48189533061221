import React, {Component} from 'react';
import LeopardSecurity from '../security/LeopardSecurity';

class LeopardLoginUserInfoPanel extends Component {
    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    logoutButtonOnClick = () => {
        LeopardSecurity.UserLogout();
    };

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        return (
            <React.Fragment>
                <div className="m-dropdown__body leopard-userinfo-panel">
                    <div className="orange-arrow-top"></div>
                    <div className="m-dropdown__content">
                        <div className={"leopard-leftmenu-category"}>
                            {"ACCOUNT"}
                        </div>
                        <div className={"leopard-leftmenu-item"}>
                            <div className={"leopard-leftmenu-item-text"}
                                 onClick={(e) => this.logoutButtonOnClick(e)}>
                                Logout
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LeopardLoginUserInfoPanel;

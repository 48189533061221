import React, {Component} from 'react';
import $ from 'jquery';
import LDH from '../helpers/LeopardDataHelper';
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LeopardAPIGatewayConfig from "./LeopardAPIGatewayConfig";

class LeopardAnalyticsDashboardEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.analyticsDefinition = [];
        this.dataViewId = null;
    }

    componentDidMount() {
        let that = this;
        this.analyticsDefinition = this.props.analyticsDefinition;
        this.dataViewId = this.props.dataViewId;

        let dashboardId = this.analyticsDefinition.quicksightDashboardId;
        let postTemplate = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate);
        postTemplate.type = "leopardsystems.quicksight.getdashboardurl";
        postTemplate.source = LDH.GenerateGuid();

        let postData = [
            {
                "dashboardId": dashboardId,
                "namespace": "default"
            }
        ];
        LeopardAjaxHelper.SendRequestByEventSync(function (response) {
            let embedUrl = response.data.EmbedUrl;
            $("#Analytics_iFrame_" + that.dataViewId).attr("src", embedUrl);
        }, function (error, errorHandler) {

        }, postTemplate, postData);
    }

    setObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) ||
            !LDH.IsObjectNull(this.uiObjectInstances[data.controlName])) {
            return false;
        }
        this.uiObjectInstances[data.controlName] = data.ref;
        return true;
    };

    refreshOnClick = () => {

    };

    analyticsToolbar = () => {
        let that = this;

        return (
            <React.Fragment>
                <div className={"leopard-analytics-toolbar"} style={{minHeight: "30px"}}>
                    <span id={"gridViewToobar_" + that.props.dataViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{color: "#FF8100", fontSize: "18px"}}></i>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                elementId={"Analytics_TopBar_Refresh_" + that.props.dataViewId}
                                labelText={"Refresh"} width={250} title={"Refresh"}
                                onClick={(e) => this.refreshOnClick({e: e})}
                                text={"The Refresh button allows you to refresh data and repaint the analytics dashboard."}/>
                        </span>
                </div>
            </React.Fragment>
        )
    };

    render() {
        return (
            <React.Fragment>
                {this.analyticsToolbar()}
                <div style={{height: "calc(100% - 30px)", overflow: "hidden"}}>
                    <iframe style={{height: "100%", width: "100%", border: "none"}} frameBorder={"0"}
                            id={"Analytics_iFrame_" + this.props.dataViewId}>
                    </iframe>
                </div>
            </React.Fragment>
        );
    }
}

export default LeopardAnalyticsDashboardEngine;